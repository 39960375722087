import { Button, DatePicker, Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { getCOAView } from '../../../API/GetData';
import TableCOA from './TableCOA';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { AkhirTahun, AuthData, AwalTahun } from '../../../Components/Global';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const SubCOA = () => {
  const userData = AuthData();
  const [filterForm] = Form.useForm();
  const [data, setData] = useState([])
  const [Loading, setLoading] = useState(false);

  const fetchData = async (Date1, Date2) => {
    try {
      setLoading(true);
      const res = await getCOAView(Date1 ? Date1 : AwalTahun, Date2 ? Date2 : AkhirTahun);
      setData(res);
    } catch (error) {
      console.log();
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [])

  useEffect(() => {
    filterForm.setFieldsValue({
      PublishDate: [dayjs(AwalTahun), dayjs(AkhirTahun)]
    })
  }, [filterForm]);


  const onFilterData = async (values) => {

    const { COACode, SampleCode, OrderCode, COAStatus, PublishDate } = values;

    try {
      setLoading(true);
      const startDate = PublishDate?.[0] ? dayjs(PublishDate[0]).format('YYYY-MM-DD') : AwalTahun;
      const endDate = PublishDate?.[1] ? dayjs(PublishDate[1]).format('YYYY-MM-DD') : AkhirTahun;

      const response = await getCOAView(startDate, endDate);

      const filtered = response.filter(item => {

        const matchesCOACode = !COACode ||
          item.COACode === COACode;

        const matchesSampleCode = !SampleCode ||
          item.SampleCode === SampleCode;

        const matchesOrderCode = !OrderCode ||
          item.OrderCode === OrderCode;

        const matchesCOAStatus = !COAStatus ||
          item.COAStatus === COAStatus;

        return matchesCOACode && matchesSampleCode && matchesOrderCode && matchesCOAStatus;
      });

      setData(filtered);

    } catch (error) {
      console.error('Error filtering data:', error);
    } finally {
      setLoading(false);
    }


  };

  const onResetFilter = () => {
    filterForm.resetFields();
    filterForm.setFieldsValue({
      PublishDate: [dayjs(AwalTahun), dayjs(AkhirTahun)]
    })
    fetchData();
  };

  return (
    <div className="bg-white p-4 rounded-md">
      <h1 className="w-full border-b-2 text-lg pb-2">List of Certificate of Analysis</h1>

      <Form
        name="filterForm"
        layout="vertical"
        onFinish={onFilterData}
        autoComplete="off"
        form={filterForm}
        className="relative border-2 rounded-md p-4 my-4"
      >
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <Form.Item
            label="Search by Certificate Code"
            name="COACode"
          >
            <Input placeholder="Search by Certificate Code" />
          </Form.Item>

          <Form.Item
            label="Search by Sample Code"
            name="SampleCode"
          >
            <Input placeholder="Search by Sample Code" />
          </Form.Item>

          <Form.Item
            label="Search by Order Code"
            name="OrderCode"
          >
            <Input placeholder="Search by Order Code" />
          </Form.Item>

          <Form.Item
            label="All COA Status"
            name="COAStatus"
          >
            <Select
              placeholder="All Status"
              defaultValue="AllStatus"
              options={[
                {
                  value: 'AllStatus',
                  label: 'All Status',
                },
                {
                  value: 'Process',
                  label: 'Process',
                },
                {
                  value: 'Published',
                  label: 'Published',
                },
                {
                  value: 'Cancel',
                  label: 'Cancel',
                },
              ]}
            />
          </Form.Item>

          <Form.Item
            label="Publish Date"
            name="PublishDate"
            className="w-full"
          >
            <RangePicker className="w-full" />
          </Form.Item>

        </div>

        <div className="flex justify-end gap-2">
          <Button type="primary" htmlType="submit">Submit</Button>
          <Button type="primary" htmlType="submit" danger onClick={onResetFilter}>Cancel</Button>
        </div>
      </Form>

      {userData && (userData.Role === "Admin" || userData.Role === "SuperAdmin") && (
        <div className="flex justify-end my-4">
          <Link to={'form'}>
            <Button type="primary" icon={<PlusOutlined />}>Add New</Button>
          </Link>
        </div>
      )}

      <TableCOA isFilteredData={data} Loading={Loading} />

    </div>
  )
}

export default SubCOA;
