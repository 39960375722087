import React, { useEffect, useState } from 'react'
import { getInvoiceView } from '../../API/GetData';
import { Button, DatePicker, Form, Input, Select } from 'antd';
import TableInvoice from './TableInvoice';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { AkhirTahun, AuthData, AwalTahun } from '../../Components/Global';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const Invoice = () => {
    const userData = AuthData();
    const [filterForm] = Form.useForm();
    const [data, setData] = useState([]);
    const [Loading, setLoading] = useState(false);

    const fetchData = async (Date1, Date2) => {
        try {
            const response = await getInvoiceView(Date1 ? Date1 : AwalTahun, Date2 ? Date2 : AkhirTahun);
            setData(response);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);


    useEffect(() => {
        filterForm.setFieldsValue({
            InvoiceDate: [dayjs(AwalTahun), dayjs(AkhirTahun)]
        })
    }, [filterForm]);


    const onFilterData = async (values) => {
        const { InvoiceNo, OrderCode, PaymentStatus, InvoiceDate, InvoiceSentDate } = values;

        try {
            setLoading(true);
            const startDate = InvoiceDate?.[0] ? dayjs(InvoiceDate[0]).format('YYYY-MM-DD') : AwalTahun;
            const endDate = InvoiceDate?.[1] ? dayjs(InvoiceDate[1]).format('YYYY-MM-DD') : AkhirTahun;

            const response = await getInvoiceView(startDate, endDate);

            const filtered = response.filter(item => {

                const matchesInvoiceNo = !InvoiceNo ||
                    item.InvoiceNo === InvoiceNo;

                const matchesOrderCode = !OrderCode ||
                    item.OrderCode === OrderCode;

                const matchesPaymentStatus = !PaymentStatus ||
                    item.PaymentStatus === PaymentStatus;

                const matchesInvoiceSentDate = InvoiceSentDate
                    ? dayjs(item.InvoiceSentDate).isBetween(InvoiceSentDate[0], InvoiceSentDate[1], 'day', '[]')
                    : true;

                return matchesInvoiceNo && matchesOrderCode && matchesPaymentStatus && matchesInvoiceSentDate;
            });

            setData(filtered);

        } catch (error) {
            console.error('Error filtering data:', error);
        } finally {
            setLoading(false);
        }
    }

    const onResetFilter = () => {
        filterForm.resetFields();
        filterForm.setFieldsValue({
            InvoiceDate: [dayjs(AwalTahun), dayjs(AkhirTahun)]
        });
        fetchData();
    };

    return (
        <div className="bg-white p-4 rounded-md">
            <h1 className="w-full border-b-2 text-lg pb-2">List of Sample</h1>
            <Form
                name="filterForm"
                layout="vertical"
                onFinish={onFilterData}
                autoComplete="off"
                form={filterForm}
                className="relative border-2 rounded-md p-4 my-4"
            >
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">

                    <Form.Item
                        label="Search by Invoice No"
                        name="InvoiceNo"
                    >
                        <Input placeholder="Search by Invoice No" />
                    </Form.Item>

                    <Form.Item
                        label="Search by Order Code"
                        name="OrderCode"
                    >
                        <Input placeholder="Search by Order Code" />
                    </Form.Item>

                    <Form.Item
                        label="All Status"
                        name="PaymentStatus"
                    >
                        <Select
                            placeholder="All Invoice Status"
                            defaultValue="AllInvoiceStatus"
                            options={[
                                {
                                    value: 'AllInvoiceStatus',
                                    label: 'All Invoice Status',
                                },
                                {
                                    value: 'Process',
                                    label: 'Process',
                                },
                                {
                                    value: 'Sent to Customer',
                                    label: 'Sent to Customer',
                                },
                                {
                                    value: 'PAID',
                                    label: 'PAID',
                                },
                                {
                                    value: 'Outstanding',
                                    label: 'Outstanding',
                                },
                            ]}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Invoice Date"
                        name="InvoiceDate"
                        className="w-full"
                    >
                        <RangePicker className="w-full" />
                    </Form.Item>

                    <Form.Item
                        label="Sent Date"
                        name="InvoiceSentDate"
                        className="w-full"
                    >
                        <RangePicker className="w-full" />
                    </Form.Item>


                </div>

                <div className="flex justify-end gap-2">
                    <Button type="primary" htmlType="submit">Submit</Button>
                    <Button type="primary" htmlType="submit" danger onClick={onResetFilter}>Cancel</Button>
                </div>

            </Form>

            {userData && (userData.Role === "Admin" || userData.Role === "SuperAdmin") && (
                <div className="flex justify-end my-4">
                    <Link to={'form'}>
                        <Button type="primary" icon={<PlusOutlined />}>Add New</Button>
                    </Link>
                </div>

            )}

            <TableInvoice isFilteredData={data} Loading={Loading} />

        </div>
    )
}

export default Invoice