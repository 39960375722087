import { Button, DatePicker, Form, message, Tooltip } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { getCustomerDetail, getSalesMonthGrowth, getSalesYTD, getTOPYearSales } from '../../API/GetData';
import dayjs from 'dayjs';
import TableAnalytic from './Table';
import { AuthData } from '../../Components/Global';
import ChartSMG from './Charts/ChartSMG';
import ChartSalesYTD from './Charts/ChartSalesYTD';
import ChartTopYearSales from './Charts/ChartTopYearSales';
import { ExportOutlined, FileExcelOutlined, FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import InputModalSelected from '../../Components/InputModalSelected';

const addSpaceBetweenCapital = (str) => {
  return str.replace(/([a-z])([A-Z])/g, "$1 $2");
};

// Fungsi untuk mengonversi angka ke format Rupiah
const toRupiah = (num) => {
  if (typeof num !== "number") return num;
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(num);
};


const Analytic = () => {
  const userData = AuthData();
  const [filterForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const [dataCustomer, setDataCustomer] = useState([]);
  const [selectCustomer, setSelectCustomer] = useState(null);
  const CustomerNames = selectCustomer && selectCustomer.map(item => item.CustomerName).join(', ');
  const CustomerCodes = selectCustomer && selectCustomer.map(item => item.CustomerId).join(',');
  const AccountId = selectCustomer && selectCustomer.map(item => item.AccountID).join(',');

  const [openCustomer, setOpenCustomer] = useState(null);

  const [salesMG, setSalesMG] = useState([]);
  const [columnsSalesMG, setColumnsSalesMG] = useState([]);

  const [salesYTD, setSalesYTD] = useState([]);
  const [columnsSalesYTD, setColumnsSalesYTD] = useState([]);

  const [TOPSales, setTOPSales] = useState([]);
  const [columnsTOPSales, setColumnsTOPSales] = useState([]);

  const [AccountID, setAccountID] = useState(null);
  const [Date, setDate] = useState(null);

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        setIsLoading(true);
        const res = await getCustomerDetail();
        if (userData.Role === "Admin") {
          const selectDefaultCS = res[0];
          setSelectCustomer(selectDefaultCS);
        }
        setDataCustomer(res);
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };
    if (openCustomer) {
      fetchCustomer();
      setOpenCustomer(false);
    }
    setAccountID(selectCustomer ? selectCustomer.AccountID : '');
    setDate(dayjs().format('YYYY-MM-DD'));
  }, [openCustomer, selectCustomer, userData.Role]);

  const fetchAnalytic = useCallback(async () => {
    try {
      const resSMG = await getSalesMonthGrowth(AccountID, Date);
      setSalesMG(resSMG);
      if (resSMG.length > 0) {
        const SalesMG = Object.keys(resSMG[0])
          .filter((key) => key !== "AccountID" && key !== "CustomerId")
          .map((key) => ({
            title: addSpaceBetweenCapital(key),
            dataIndex: key,
            key: key,
            render: (value) => (typeof value === "number" ? toRupiah(value) : value),
          }));

        setColumnsSalesMG(SalesMG);
      }

      const resSalesYTD = await getSalesYTD(AccountID, Date);
      setSalesYTD(resSalesYTD);
      if (resSalesYTD.length > 0) {
        const salesYTDColumns = Object.keys(resSalesYTD[0])
          .filter((key) => key !== "AccountID" && key !== "CustomerId")
          .map((key) => ({
            title: addSpaceBetweenCapital(key),
            dataIndex: key,
            key: key,
            render: (value) =>
              typeof value === "number" && key !== "Year" ? toRupiah(value) : value,
          }));
        setColumnsSalesYTD(salesYTDColumns);
      }

      const resTOPSales = await getTOPYearSales(AccountID, Date);
      setTOPSales(resTOPSales);
      if (resTOPSales.length > 0) {
        const TOPSales = Object.keys(resTOPSales[0])
          .filter((key) => key !== "AccountID" && key !== "CustomerId")
          .map((key) => ({
            title: addSpaceBetweenCapital(key),
            dataIndex: key,
            key: key,
            render: (value) => (typeof value === "number" ? toRupiah(value) : value),
          }));
        setColumnsTOPSales(TOPSales);
      }
    } catch (error) {
      console.log(error);
    }
  }, [AccountID, Date]);

  useEffect(() => {
    if (selectCustomer) {
      filterForm.setFieldsValue({
        CustomerName: CustomerNames,
        // Date: dayjs(),
      });
      setAccountID(AccountId);
    }
  }, [AccountId, CustomerNames, filterForm, selectCustomer]);

  const onFilterData = async (values) => {
    try {
      const payload = {
        ...values,
        AccountID: AccountID,
        CustomerName: values.CustomerName ? values.CustomerName : '',
        Date: dayjs(values.Date).format('YYYY-MM-DD'),
      };

      setAccountID(payload.AccountID);
      setDate(payload.Date);
      fetchAnalytic();
    } catch (error) {
      console.log(error);
    }
  };

  const onResetFilter = () => {
    filterForm.resetFields();
    setSalesMG([]);
    setSalesYTD([]);
    setTOPSales([]);
    setSelectCustomer(null);
    setAccountID("");
    setDate(dayjs());
  };

  // Fungsi untuk meng-export tabel menjadi PDF
  const exportPDF = (tableData, tableColumns, title) => {
    if (tableData.length === 0) {
      message.error("No data found to export!");
      return;
    }
    const doc = new jsPDF();
    doc.text(title, 20, 10);
    const tableColumn = tableColumns.map((col) => col.title);
    const tableRows = tableData.map((data) => tableColumns.map((col) => data[col.dataIndex]));
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
    });
    doc.save(`${title}.pdf`);
  };

  const exportToExcel = (tableData, title) => {
    if (tableData.length === 0) {
      message.error("No data found to export!");
      return;
    }

    // Membuat workbook baru
    const wb = XLSX.utils.book_new();

    // Mengkonversi data ke format worksheet
    const ws = XLSX.utils.json_to_sheet(tableData);

    // Menambahkan worksheet ke workbook
    XLSX.utils.book_append_sheet(wb, ws, title);

    // Menyimpan file Excel
    XLSX.writeFile(wb, `${title}.xlsx`);
  };

  useEffect(() => {
    filterForm.setFieldsValue({
      Date: dayjs(),
    })
  }, [filterForm]);


  return (
    <>
      <div className="bg-white p-4 rounded-md">
        <h1 className="text-xl font-medium border-b-2 mb-4 pb-2">Analytic</h1>

        <Form
          name="filterForm"
          layout="vertical"
          onFinish={onFilterData}
          autoComplete="off"
          form={filterForm}
          className="relative border-2 rounded-md p-4 my-4"
        >
          <p className="absolute -top-3 left-2 bg-white px-2 text-md">Filter Analytic</p>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            {(userData.Role === "Admin" || userData.Role === "SuperAdmin") && (
              <InputModalSelected
                title="CUSTOMER"
                label="Customer"
                name="CustomerName"
                dataSource={dataCustomer}
                loading={isLoading}
                columns={columnsCustomer}
                onData={(values) => setSelectCustomer(values)}
                onOpenModal={(values) => setOpenCustomer(values)}
                onRequired={true}
              />
            )}

            <Form.Item label="Date" name="Date" className="w-full">
              <DatePicker className="w-full" />
            </Form.Item>
          </div>

          <div className="flex justify-end gap-2">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button type="primary" htmlType="button" danger onClick={onResetFilter}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>

      <div className="bg-white mt-2 p-4 rounded-md">
        <div className="flex items-center gap-2 mb-2">
          <h1 className="text-xl font-bold">SALES MONTH GROWTH</h1>
          <div className="flex item-center">
            <Tooltip title="Export to PDF">
              <Button
                icon={<FilePdfOutlined />}
                type="text"
                onClick={() => exportPDF(salesMG, columnsSalesMG, "Sales Month Growth")}
              />
            </Tooltip>
            <Tooltip title="Export to Excel">
              <Button
                icon={<FileExcelOutlined />}
                type="text"
                onClick={() => exportToExcel(salesMG, "Sales Month Growth")}
              />
            </Tooltip>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-4 lg:gap-0">
          <div className="w-full">
            <TableAnalytic columns={columnsSalesMG} dataSource={salesMG} isLoading={isLoading} />
          </div>
          <div className="w-full">
            <ChartSMG dataSource={salesMG} />
          </div>
        </div>
      </div>


      <div className="bg-white mt-2 p-4 rounded-md">
        <div className="flex items-center gap-2 mb-2">
          <h1 className="text-xl font-bold">SALES YTD</h1>
          <div className="flex item-center">
            <Tooltip title="Export to PDF">
              <Button
                icon={<FilePdfOutlined />}
                type="text"
                onClick={() => exportPDF(salesYTD, columnsSalesYTD, "Sales YTD")}
              />
            </Tooltip>
            <Tooltip title="Export to Excel">
              <Button
                icon={<FileExcelOutlined />}
                type="text"
                onClick={() => exportToExcel(salesYTD, "Sales YTD")}
              />
            </Tooltip>
          </div>
        </div>


        <div className="flex flex-col lg:flex-row gap-4 lg:gap-0">
          <div className="w-full">
            <TableAnalytic columns={columnsSalesYTD} dataSource={salesYTD} isLoading={isLoading} />
          </div>
          <div className="w-full">
            <ChartSalesYTD dataSource={salesYTD} />
          </div>
        </div>
      </div>

      <div className="bg-white mt-2 p-4 rounded-md">

        <div className="flex items-center gap-2 mb-2">
          <h1 className="text-xl font-bold">TOP YEAR SALES</h1>
          <div className="flex item-center">
            <Tooltip title="Export to PDF">
              <Button
                icon={<FilePdfOutlined />}
                type="text"
                onClick={() => exportPDF(TOPSales, columnsTOPSales, "Top Year Sales")}
              />
            </Tooltip>
            <Tooltip title="Export to Excel">
              <Button
                icon={<FileExcelOutlined />}
                type="text"
                onClick={() => exportToExcel(TOPSales, "Top Year Sales")}
              />
            </Tooltip>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row gap-4 lg:gap-0">
          <div className="w-full">
            <TableAnalytic columns={columnsTOPSales} dataSource={TOPSales} isLoading={isLoading} />
          </div>
          <div className="w-full">
            <ChartTopYearSales dataSource={TOPSales} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Analytic;


const columnsCustomer = [
  {
    title: "AccountID",
    dataIndex: "AccountID",
    key: "AccountID",
  },
  {
    title: "CustomerId",
    dataIndex: "CustomerId",
    key: "CustomerId",
  },
  {
    title: "CustomerName",
    dataIndex: "CustomerName",
    key: "CustomerName",
  },
  {
    title: "ContactPerson",
    dataIndex: "ContactPerson",
    key: "ContactPerson",
  },
  {
    title: "Address",
    dataIndex: "Address",
    key: "Address",
  },
  {
    title: "EmailContact",
    dataIndex: "EmailContact",
    key: "EmailContact",
  },
  {
    title: "Phone",
    dataIndex: "Phone",
    key: "Phone",
  },
  {
    title: "MobilePhone",
    dataIndex: "MobilePhone",
    key: "MobilePhone",
  },
  {
    title: "Fax",
    dataIndex: "Fax",
    key: "Fax",
  },
];