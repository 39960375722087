import { Button, DatePicker, Form, Input, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import TableSample from './TableSample';
import { getSampleView } from '../../../API/GetData';
import dayjs from 'dayjs';
import { AkhirTahun, AwalTahun } from '../../../Components/Global';

const { RangePicker } = DatePicker;

const Sample = () => {
  const [filterForm] = Form.useForm();
  const [data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);

  const fetchData = async (Date1, Date2) => {
    try {
      const response = await getSampleView(Date1, Date2);
      setData(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  useEffect(() => {
    filterForm.setFieldsValue({
      COADueDate: [dayjs(AwalTahun), dayjs(AkhirTahun)]
    })
  }, [filterForm]);


  const onFilterData = async (values) => {
    const { CodeorName, OrderCode, Status, COADueDate } = values;

    try {
      setLoading(true);
      const startDate = COADueDate?.[0] ? dayjs(COADueDate[0]).format('YYYY-MM-DD') : AwalTahun;
      const endDate = COADueDate?.[1] ? dayjs(COADueDate[1]).format('YYYY-MM-DD') : AkhirTahun;

      const response = await getSampleView(startDate, endDate);

      const filtered = response.filter(item => {

        const matchesCodeorName = !CodeorName ||
          item.SampleCode.toLowerCase().includes(CodeorName.toLowerCase()) ||
          item.SampleName.toLowerCase().includes(CodeorName.toLowerCase())

        const matchesOrderCode = !OrderCode ||
          item.OrderCode === OrderCode;

        const matchesStatus = !Status ||
          item.Status === Status;

        return matchesCodeorName && matchesOrderCode && matchesStatus;
      });

      setData(filtered);

    } catch (error) {
      console.error('Error filtering data:', error);
    } finally {
      setLoading(false);
    }
  };

  const onResetFilter = () => {
    filterForm.resetFields();
    filterForm.setFieldsValue({
      COADueDate: [dayjs(AwalTahun), dayjs(AkhirTahun)]
    })
    fetchData();
  };

  return (
    <div className="bg-white p-4 rounded-md">
      <h1 className="w-full border-b-2 text-lg pb-2">List of Sample</h1>

      <Form
        name="filterForm"
        layout="vertical"
        onFinish={onFilterData}
        autoComplete="off"
        form={filterForm}
        className="relative border-2 rounded-md p-4 my-4"
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">

          <Form.Item
            label="Search Sample Code or Name"
            name="CodeorName"
          >
            <Input placeholder="Search Sample Code or Name" />
          </Form.Item>

          <Form.Item
            label="Search by Order Code"
            name="OrderCode"
          >
            <Input placeholder="Search by Order Code" />
          </Form.Item>

          <Form.Item
            label="All Status"
            name="Status"
          >
            <Select
              placeholder="All Status"
              defaultValue="AllStatus"
              options={[
                {
                  value: 'AllStatus',
                  label: 'All Status',
                },
                {
                  value: 'Process',
                  label: 'Process',
                },
                {
                  value: 'Published',
                  label: 'Published',
                },
                {
                  value: 'Cancel',
                  label: 'Cancel',
                },
              ]}
            />
          </Form.Item>

          <Form.Item
            label="COA Release Due Date"
            name="COADueDate"
            className="w-full"
          >
            <RangePicker className="w-full" />
          </Form.Item>

        </div>

        <div className="flex justify-end gap-2">
          <Button type="primary" htmlType="submit">Submit</Button>
          <Button type="primary" htmlType="submit" danger onClick={onResetFilter}>Cancel</Button>
        </div>

      </Form >

      <TableSample isFilteredData={data} Loading={Loading} />

    </div >
  )
}

export default Sample;
