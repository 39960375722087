import { Button, message, Modal } from "antd";
import { useState } from "react";
import * as XLSX from 'xlsx';

const ImportExcel = ({ onImportData }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dataExcel, setDataExcel] = useState([]);
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);


    function handleFileUpload(e) {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];

            // Menggunakan opsi `range` untuk mulai dari baris kedua
            let data = XLSX.utils.sheet_to_json(ws, { range: 1 });

            // Menghilangkan spasi di field (key) JSON
            data = data.map(row => {
                const newRow = {};
                Object.keys(row).forEach(key => {
                    let newKey = key;

                    // Jika key adalah "Technical/ReferenceMethod", ubah menjadi "Method"
                    if (newKey === "Technical/Reference Method") {
                        newKey = "Method";
                    }

                    newRow[newKey] = row[key];
                });
                return newRow;
            });

            setDataExcel(data);
            console.log("Data Excel", data);
            
            // Gunakan data di sini, misalnya dengan menyimpannya ke state
        };

        reader.readAsBinaryString(file);
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        let limitedData = dataExcel;

        if (dataExcel.length === 0) {
            message.error("No files uploaded!");
            if (from && from < 3) {
                message.error("From min select column 3!")
                return;
            }
            return;
        }


        if (from && to) {
            const fromIndex = parseInt(from, 10);
            const toIndex = parseInt(to, 10);

            if (isNaN(fromIndex) || isNaN(toIndex)) {
                message.error("from or to is not a valid number");
                return;
            }

            if (toIndex < fromIndex) {
                message.error("The 'to' row cannot be less than the 'from' row");
                return;
            }

            limitedData = dataExcel.slice(fromIndex - 3, toIndex - 2);

            if (limitedData.length === 0) {
                message.error("No data found in the selected range!");
                return;
            }

        }

        // localStorage.setItem('dataExcel', JSON.stringify(limitedData));
        setIsModalOpen(false);
        onImportData(limitedData);
    };



    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button
                type="primary"
                danger
                onClick={showModal}
            >
                Import
            </Button>
            <Modal
                centered
                title="Import Data WorkSheet"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <input type="file" onChange={handleFileUpload} accept=".xlsx, .xls" className="my-2" />
                <p>Rows</p>
                <div className="flex items-center gap-3 mt-2">
                    <input
                        type="number"
                        className="border rounded p-1"
                        onChange={(values) => setFrom(values.target.value)}
                        min={3}
                    />
                    <p>to</p>
                    <input type="number" className="border rounded p-1" onChange={(values) => setTo(values.target.value)} />
                </div>
            </Modal>
        </>
    )
}

export default ImportExcel;