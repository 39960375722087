import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Select } from 'antd';
import TableOrder from './TableOrder';
import { getOrderView } from '../../../API/GetData';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { AkhirTahun, AuthData, AwalTahun } from '../../../Components/Global';
import ImportExcelOrder from './ImportExcelOrder';

const { RangePicker } = DatePicker;

const Order = () => {
  const userData = AuthData();
  const [filterForm] = Form.useForm();
  const [data, setData] = useState([]);

  const [importData, setImportData] = useState([]);

  // console.log("Import Data", importData);

  const [loading, setLoading] = useState(false);

  const fetchData = async (Date1, Date2, OrderCode) => {
    try {
      setLoading(true);
      const res = await getOrderView(Date1 ? Date1 : AwalTahun, Date2 ? Date2 : AkhirTahun, OrderCode);
      setData(res);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    filterForm.setFieldsValue({
      recived: [dayjs(AwalTahun), dayjs(AkhirTahun)]
    })
  }, [filterForm]);

  const onFilterData = async (values) => {
    const { OrderCode, PaymentStatus, Priority, recived, bysubmitted } = values;

    try {
      setLoading(true);
      const startDate = recived?.[0] ? dayjs(recived[0]).format('YYYY-MM-DD') : AwalTahun;
      const endDate = recived?.[1] ? dayjs(recived[1]).format('YYYY-MM-DD') : AkhirTahun;
      const orderCode = OrderCode || '';

      const response = await getOrderView(startDate, endDate, orderCode);

      const filtered = response.filter(item => {

        const matchesOrderCode = !OrderCode ||
          item.OrderCode === OrderCode;

        const matchesPaymentStatus = !PaymentStatus ||
          item.PaymentStatus === PaymentStatus;

        const matchesPriority = !Priority ||
          item.Priority === Priority;

        const matchesSubmittedBy = !bysubmitted ||
          item.SubmittedBy === bysubmitted;
        // item.SubmittedBy.includes(bysubmitted);

        return matchesOrderCode && matchesPaymentStatus && matchesPriority && matchesSubmittedBy;
      });

      setData(filtered);

    } catch (error) {
      console.error('Error filtering data:', error);
    } finally {
      setLoading(false);
    }
  };

  const onResetFilter = () => {
    filterForm.resetFields();
    filterForm.setFieldsValue({
      recived: [dayjs(AwalTahun), dayjs(AkhirTahun)]
    })
    fetchData();
  };

  const handleClearStorage = () => {
    localStorage.removeItem('formOrderData');
    localStorage.removeItem('formSampleData');
  }

  return (
    <div className="bg-white p-4 rounded-md">

      <Form
        name="filterForm"
        layout="vertical"
        onFinish={onFilterData}
        autoComplete="off"
        form={filterForm}
        className="relative border-2 rounded-md p-4 my-4"
      >
        <p className="absolute -top-3 left-2 bg-white px-2 text-md">Filter Order</p>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">

          <Form.Item label="Search by Order Number" name="OrderCode">
            <Input placeholder="Search by Order Number" />
          </Form.Item>

          <Form.Item label="All Payment Status" name="PaymentStatus">
            <Select
              placeholder="All Payment Status"
              defaultValue="All Payment Status"
              options={[
                { value: '', label: 'All Payment Status' },
                { value: 'UNPAID', label: 'UNPAID' },
                { value: 'PAID', label: 'PAID' },
              ]}
            />
          </Form.Item>

          <Form.Item label="All Priority" name="Priority">
            <Select
              placeholder="All Priority"
              defaultValue="All Priority"
              options={[
                { value: '', label: 'All Priority' },
                { value: 'Normal', label: 'Normal' },
                { value: 'Very Urgent', label: 'Very Urgent' },
                { value: 'Urgent', label: 'Urgent' },
              ]}
            />
          </Form.Item>

          <Form.Item label="Recived" name="recived" className="w-full">
            <RangePicker className="w-full" />
          </Form.Item>

          <Form.Item label="Search by Submitted" name="bysubmitted">
            <Input placeholder="Search by Submitted" />
          </Form.Item>

        </div>

        <div className="flex justify-end gap-2">
          <Button type="primary" htmlType="submit">Submit</Button>
          <Button type="primary" htmlType="button" danger onClick={onResetFilter}>Cancel</Button>
        </div>
      </Form>

      {userData && (userData.Role === "Admin" || userData.Role === "SuperAdmin") && (
        <div className="flex justify-end gap-2 my-4">
          {/* <ImportExcelOrder onImportData={(values) => setImportData(values)} /> */}
          <Link to={'form'}>
            <Button type="primary" icon={<PlusOutlined />} onClick={handleClearStorage}>Add New</Button>
          </Link>
        </div>
      )}

      <TableOrder isFilteredData={data} isLoading={loading} />
    </div>
  )
}

export default Order;
