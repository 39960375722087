import { Button, Form, Input, Modal, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import HeaderTitle from './HeaderTitle';
import SearchInput from './SearchInput';
import { CloseCircleFilled } from '@ant-design/icons';

const InputModalSelected = ({ title, label, name, dataSource, loading, columns, onData, onOpenModal, onDetail, onEdit, onRequired }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectRow, setSelectRow] = useState([]);

    useEffect(() => {
        if (onEdit) {
            setSelectedRowKeys([onEdit.key]);
        }
    }, [onEdit]);


    const showModal = () => {
        setIsModalOpen(true);
        if (!onEdit) {
            onOpenModal(true);
        }
    };

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = dataSource.filter((item) =>
        Object.values(item).some((val) =>
            val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
    );

    const rowSelection = {
        type: "checkbox",
        selectedRowKeys, // Bind selected row keys to rowSelection
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectRow(selectedRows);
        },
    };

    const handleRowClick = (record, rowIndex) => {
        // Check if the row is already selected
        const key = record.key;
        const isSelected = selectedRowKeys.includes(key);

        let newSelectedRowKeys;
        let newSelectedRows;

        if (isSelected) {
            // If already selected, remove it
            newSelectedRowKeys = selectedRowKeys.filter(k => k !== key);
            newSelectedRows = selectRow.filter(item => item.key !== key);
        } else {
            // If not selected, add it
            newSelectedRowKeys = [...selectedRowKeys, key];
            newSelectedRows = [...selectRow, record];
        }

        setSelectedRowKeys(newSelectedRowKeys);
        setSelectRow(newSelectedRows);
    };

    const handleSubmit = () => {
        // console.log("Data Select", selectRow.map((item) => item.FacilityName).join(", "));        
        onData(selectRow)
        setIsModalOpen(false);
    }

    const handleClose = () => {
        setIsModalOpen(false);
        setSelectedRowKeys([]);
        onData([])
    }

    const handleClear = () => {
        setSelectedRowKeys([]);
        onData([])
    };


    return (
        <div>
            <Form.Item
                label={!onDetail ? label : ''}
                name={name}
                rules={[
                    !onRequired && {
                        required: true,
                        message: `Please input your ${label}!`,
                    },
                ]}
            >
                <Input
                    onClick={showModal}
                    readOnly
                    placeholder={`${label}`}
                    suffix={
                        <CloseCircleFilled
                            onClick={handleClear}
                            className="cursor-pointer text-gray-400 hover:text-gray-500 transition-all delay-100"
                        />
                    }
                />
            </Form.Item>

            <Modal
                centered
                title={<HeaderTitle title={title} subtitle="" />}
                open={isModalOpen}
                closable={false}
                width={`80%`}
                style={{
                    margin: "30px",
                }}
                footer={
                    <>
                        <Button type="primary" onClick={handleSubmit}>Submit</Button>
                        <Button onClick={handleClose}>Close</Button>
                    </>
                }
            >
                <div className="flex justify-end mb-2">
                    <SearchInput value={searchText} onChange={handleSearch} />
                </div>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={filteredData}
                    rowSelection={rowSelection}
                    onRow={(record, rowIndex) => ({
                        onClick: () => {
                            handleRowClick(record, rowIndex);
                        },
                    })}
                    scroll={
                        columns.length > 8 && { x: 2000 }
                    }
                />
            </Modal>
        </div>
    );
};

export default InputModalSelected;
